module.exports = [{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
